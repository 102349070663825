import './style.css'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

import {useEffect, useState} from "react";
import {Delete, Edit} from "@mui/icons-material";
import MedalForm from "../../components/createMedalForm";
import getWinners from "../../apis/getWinners";

const Home = ({setToast}) => {
    const [winners, setWinners] = useState([]);

    const fetchWinners = async () => {
        try {
            const response = await getWinners();
            if (response.winners.length > 0) {
                setWinners(response.winners)
            } else {
                setWinners([])
            }
        } catch (e) {
            setWinners([])
            console.error(e);
        }
    }

    useEffect(() => {
        fetchWinners();
    }, []);
    return (
        <>
            <section>
                <MedalForm setToast={setToast}/>
            </section>

            <section>
                <div className="inner-container-small text-start my-3 my-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Medals Table</h2>
                    <h1 className="large-text text-center upper-text">Medals Table</h1>
                </div>

                <TableContainer className='medal-table-container'>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S.no</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Medal</TableCell>
                                <TableCell>Game</TableCell>
                                <TableCell>Coach</TableCell>
                                <TableCell>Judge</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                winners?.length === 0 ? (
                                        <p>No Record found</p>
                                    ) :
                                    (winners?.map((row, index) => (
                                        <TableRow key={row?.id}>
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell>{row?.firstname + ' ' + row?.lastname || 'N/A'}</TableCell>
                                            <TableCell>{row?.medal || 'N/A'}</TableCell>
                                            <TableCell>{row?.gamename || 'N/A'}</TableCell>
                                            <TableCell>{row?.coach || 'N/A'}</TableCell>
                                            <TableCell>{row?.judge || 'N/A'}</TableCell>
                                            <TableCell style={{display: 'flex', gap: '5px'}}>
                                                <Button startIcon={<Edit/>} variant='contained'
                                                        size='small'>Edit</Button>
                                                <Button startIcon={<Delete/>} variant='contained' size='small'
                                                        color='warning'>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    )))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </>
    )
}
export default Home