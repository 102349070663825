import axiosInstance from "../utils/axiosInstance";

const addMedal = async (federationId, gameId, medal, coach, judge, participent) => {
    try {
        const response = await axiosInstance.post('/api/CRMMedalTallyData',
            {
                eventid: 1,
                gameId: gameId,
                medal: medal,
                coach: coach,
                participent: participent,
                judge: judge
            }, {
                headers: {
                    "access-token": "2424ASASDhuif@12",
                    "federationId": federationId
                }
            })
        return response.data;
    } catch (e) {
        throw e;
    }
}
export default addMedal;