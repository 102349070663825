import logo from '../assets/images/logo.png'
import '../assets/css/global.css'

const Loader = () => {
    return (
        <div className="loader">
            <img src={logo} alt="logo" width='80px'/>
            Loading...
        </div>
    )
}
export default Loader;