// store.js
import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer'; // Updated root reducer
import {resetStore} from './actions/resetAction';
import {logout as authLogout} from './auth/authSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // Persist auth and app slices
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Adjust as needed
        }),
});

export const persistor = persistStore(store);

// Logout function
export const logout = () => {
    store.dispatch(authLogout());
    store.dispatch(resetStore());
    persistor.purge().then(() => {
        localStorage.clear();
    }).catch((error) => {
        console.error('Failed to purge persisted state:', error);
    });
};