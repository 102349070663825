// rootReducer.js
import {combineReducers} from 'redux';
import {RESET_STORE} from './actions/resetAction';
import authReducer from './auth/authSlice'

const appReducerCombined = combineReducers({
    auth: authReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
        state = undefined;
    }
    return appReducerCombined(state, action);
};

export default rootReducer;
