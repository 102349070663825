import './style.css'
import logo from '../assets/images/logo.png'
import {Button, Dialog} from "@mui/material";
import {Logout} from "@mui/icons-material";
import {useState} from "react";

const Header = ({handleLogout}) => {
    const [open, setOpen] = useState(false);
    return (
        <header>
            <div className='container p-2 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img src={logo} alt='logo' width='50px'/>
                    <h4 style={{marginLeft: '10px'}}>CRM Pythiangames</h4>
                </div>
                <div>
                    <Button
                        variant={'contained'}
                        color={'warning'}
                        endIcon={<Logout/>}
                        onClick={() => setOpen(true)}
                    >
                        Logout
                    </Button>
                </div>
            </div>
            <Dialog open={open}>
                <div className="app-dialog">
                    <h2 className="">Logout Confirmation</h2>
                    <p className="">Are you sure you want to logout?</p>
                    <div>
                        <Button style={{width: '100%'}}
                                onClick={() => setOpen(false)}>Cancel
                        </Button>
                        <Button variant='contained' color='warning' style={{width: '100%'}}
                                onClick={handleLogout}>Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
        </header>
    )
}
export default Header