// authSlice.js
import {createSlice} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    username: null,
    name: null,
    federationID: null,
    accessToken: null,
    id: null,
    federationLogo: null,
    games: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const {
                username,
                name,
                federationID,
                accessToken,
                id,
                federationLogo,
                games
            } = action.payload;
            state.username = username
            state.name = name
            state.federationID = federationID
            state.accessToken = accessToken
            state.id = id
            state.federationLogo = federationLogo
            state.games = games
        },
        logout: () => initialState
    },
});

const persistConfig = {
    key: "auth",
    storage,
    whitelist: [
        'username',
        'name',
        'federationID',
        'accessToken',
        'id',
        'federationLogo',
        'games'
    ],
};

const persistedReducer = persistReducer(persistConfig, authSlice.reducer);

export const {
    setCredentials,
    logout
} = authSlice.actions;

export default persistedReducer;