import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
})

const getToken = () => localStorage.getItem("token");

axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    // if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
}, (error) => Promise.reject(error))

axiosInstance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        return Promise.reject(error);
    }
)


export default axiosInstance;