import axiosInstance from "../utils/axiosInstance";

const login = async (username, password) => {
    try {
        const response = await axiosInstance.post('/api/CRMLoginValidate',
            {
                username: username,
                password: password
            }, {
                headers: {
                    "access-token": "ADHKLkhgsBHUygv12@1",
                    "quiz-id": 1
                }
            })
        return response.data;
    } catch (e) {
        throw e;
    }
}
export default login;