import axiosInstance from "../utils/axiosInstance";

const getWinners = async () => {
    try {
        const response = await axiosInstance.post('/api/CRMGetWinners',
            {}, {
                headers: {
                    "access-token": "Panxlweuhubf@1241",
                    "gameId": 100,
                    "eventId": 1
                }
            })
        return response.data;
    } catch (e) {
        throw e;
    }
}
export default getWinners;