import '../assets/css/global.css'
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./authentication/login";
import Home from "./home/home";
import Header from "../components/header";
import Footer from "../components/footer";
import Loader from "../components/loader";
import {useEffect, useState} from "react";
import {Bounce, toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {logout} from "../redux/store";

const Main = () => {
    const {pathname} = useLocation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.addEventListener('load', () =>{
            console.log("page loaded")
            setLoading(false)

        });
    }, []);
    const showToast = (type, message) => {
        toast[type](message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce
        })
    }

    const handleLogout = () => {
        localStorage.clear();
        logout();
        navigate("/");
    };

    return (
        <>
            {loading && <Loader/>}
            <ToastContainer/>
            {
                pathname !== "/" && <Header handleLogout={handleLogout}/>
            }
            <article className='content container'>
                <Routes>
                    <Route path={'/'} element={<Login setToast={(t) => showToast(t.type, t.message)}/>}/>
                    <Route path={'/home'} element={<Home setToast={(t) => showToast(t.type, t.message)}/>}/>
                </Routes>
            </article>
            {
                pathname !== "/" && <Footer/>
            }
        </>
    )
}
export default Main;
