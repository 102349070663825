import {useEffect, useState} from 'react'
import {
    Box, CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select, TextField,
    Typography
} from '@mui/material'
import {EmojiEvents} from '@mui/icons-material'
import {useSelector} from "react-redux";
import getGameDetails from "../apis/getGameDetails";
import addMedal from "../apis/addMedal";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MedalForm({setToast}) {
    const games = useSelector((state) => state.auth.games) || [];
    const [loading, setLoading] = useState(false);
    const {federationID} = useSelector((state) => state.auth);
    const [judgesList, setJudgesList] = useState([])
    const [medalsList, setMedalsList] = useState([])
    const [participantsList, setParticipantsList] = useState([])
    const [data, setData] = useState({
        gameId: '',
        judge: '',
        participant: '',
        medal: '',
        coach: ''
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await addMedal(federationID, data.gameId, data.medal, data.coach, data.judge, data.participant);
            if (response.status === 1) {
                setToast({
                    type: 'success',
                    message: response?.message || `Login successfully`
                })
                resetForm()
            } else {
                setToast({
                    type: 'error',
                    message: response?.message || `Failed to add medal`
                })
            }
            console.log(response)
        } catch (e) {
            setToast({
                type: 'error',
                message: e?.response?.data?.message || `Something went wrong`,
            })
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const resetForm = () => {
        setData({
            gameId: '',
            judge: '',
            participant: '',
            medal: '',
            coach: ''
        })
    }

    const fetchGameDetail = async () => {
        if (!data.gameId) {
            return;
        }
        try {
            const response = await getGameDetails(data.gameId, federationID);
            setParticipantsList(response?.participents)
            setJudgesList(response?.judges)
            setMedalsList(response?.medalsInfo)
        } catch (e) {
            setToast({
                type: 'error',
                message: e?.response?.data?.message || `Something went wrong to fetch game details`,
            })
            console.error(e)
        }
    }
    useEffect(() => {
        fetchGameDetail()
    }, [data?.gameId])


    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{p: 4, mt: 4}}>
                <Box component="form" onSubmit={(e) => handleSubmit(e)}
                     sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2}}>
                        <EmojiEvents color="primary"/>
                        <Typography variant="h4" component="h1">
                            Add Medal
                        </Typography>
                    </Box>

                    <FormControl fullWidth>
                        <FormLabel>Game</FormLabel>
                        <Select
                            value={data?.gameId}
                            displayEmpty
                            required
                            onChange={(e) => setData({...data, gameId: e.target.value})}
                            sx={{mt: 1}}>
                            <MenuItem disabled value="">Select Game</MenuItem>
                            {games?.map((item) => (
                                <MenuItem value={item?.gameid}>{item?.gamename}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {
                        data.gameId &&
                        <>
                            <Box sx={{display: 'flex', gap: 2}}>
                                <FormControl fullWidth>
                                    <FormLabel>Judge</FormLabel>
                                    <Select
                                        value={data?.judge}
                                        onChange={(e) => setData({...data, judge: e.target.value})}
                                        displayEmpty
                                        required
                                        sx={{mt: 1}}>
                                        <MenuItem disabled value="">Select Judge</MenuItem>
                                        {judgesList.map((item) => (
                                            <MenuItem value={item?.Name}>{item?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <FormLabel>Participant</FormLabel>
                                    <Select
                                        value={data?.participant}
                                        onChange={(e) => setData({...data, participant: e.target.value})}
                                        displayEmpty
                                        required
                                        sx={{mt: 1}}>
                                        <MenuItem disabled value="">Select Participant</MenuItem>
                                        {participantsList.map((item) => (
                                            <MenuItem value={item?.id}>{item?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <FormControl fullWidth>
                                {/*<FormLabel>Coach name</FormLabel>*/}
                                <TextField
                                    onChange={(e) => setData({...data, coach: e.target.value})}
                                    label="Coach name"
                                    variant="outlined"/>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Medal</FormLabel>
                                <RadioGroup
                                    row
                                    value={data?.medal}
                                    required={true}
                                    onChange={(e) => setData({...data, medal: e.target.value})}
                                    sx={{mt: 1}}>
                                    {medalsList?.map((item) => (
                                        <FormControlLabel
                                            value={item?.name}
                                            control={<Radio/>}
                                            label={item?.name}/>
                                    ))}
                                    {/*<FormControlLabel
                                        value="Gold"
                                        control={<Radio/>}
                                        label="Gold"
                                        sx={{'& .MuiRadio-root.Mui-checked': {color: '#FFD700'}}}/>
                                    <FormControlLabel
                                        value="Silver"
                                        control={<Radio/>}
                                        label="Silver"
                                        sx={{'& .MuiRadio-root.Mui-checked': {color: '#C0C0C0'}}}/>
                                    <FormControlLabel
                                        value="Bronze"
                                        control={<Radio/>}
                                        label="Bronze"
                                        sx={{'& .MuiRadio-root.Mui-checked': {color: '#CD7F32'}}}/>*/}
                                </RadioGroup>
                            </FormControl>
                            <LoadingButton
                                variant='contained'
                                loading={loading}
                                loadingIndicator={
                                    <CircularProgress
                                        sx={{
                                            color: "white",
                                            mt: 2
                                        }}
                                        size={24}
                                    />
                                }
                                type="submit"
                            >
                                Submit
                            </LoadingButton>
                        </>
                    }
                </Box>
            </Paper>
        </Container>
    )
}
