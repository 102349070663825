import './style.css'
import logo from '../assets/images/logo.png'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container grid-list">
                    <div className="row">
                        <div className="col-12 text-center center-div">
                            <div className="footer-brand">
                                <a href="/" className="logo">
                                    <img src={logo} alt="footer-image" className="rotate"/>
                                </a>
                                <p className="animated_rainbow_1 text-center">Unifying Nations through Arts, Cultures
                                    and Traditional Games</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copy-right">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <div className="small mb-3 mb-md-0 text-center">
              <span className="footer-text">
                Copyright © 2024{" "}
                  <span className="text-primary">
                  <a href="#" className="footer-link">International Pythian Council.</a>
                </span>{" "}
                  All rights reserved.
              </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer