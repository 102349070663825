import axiosInstance from "../utils/axiosInstance";

const getGameDetails = async (gameId, federationId) => {
    try {
        const response = await axiosInstance.get('/api/CRMGetDetails',
            {
                headers: {
                    "access-token": "ADHKLkhgsBHUygv12@1",
                    "gameId": gameId,
                    "federationId": federationId
                }
            })
        return response.data;
    } catch (e) {
        throw e;
    }
}
export default getGameDetails;