import {useEffect, useState} from "react";
import {IconButton, CircularProgress, Paper} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import './style.css'
import '../../assets/css/global.css'
import logo from '../../assets/images/logo.png'
import {useNavigate} from "react-router-dom";
import login from "../../apis/login";
import {setCredentials} from "../../redux/auth/authSlice";
import {useDispatch} from "react-redux";

const Login = ({setToast}) => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await login(username, password);
            if (response.status === 1) {
                dispatch(
                    setCredentials({
                        username: response?.username,
                        name: response?.name,
                        federationID: response?.FederationID,
                        accessToken: response?.accessToken,
                        id: response?.ID,
                        federationLogo: response?.FerderationLogo,
                        games: response?.Games
                    })
                )
                setToast({
                    type: 'success',
                    message: response?.message || `Login successfully`
                })
                navigate('/home');
            } else {
                setToast({
                    type: 'error',
                    message: response?.message || `Login failed.`
                })
            }
        } catch (e) {
            setToast({
                type: 'error',
                message: e?.response?.data?.message || `Login failed.`
            })
            console.error(e)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='login-container'>
            <Paper elevation={3}>
                <form onSubmit={e => handleLogin(e)} className='login-form'>
                    <img src={logo} alt='Logo' width={'80px'} style={{margin: 'auto'}}/>
                    <h3 className='title-1 m-0 text-center'>Login Here</h3>
                    <p className='m-0 text-center'>Enter your username and password to login to your account</p>
                    <div className='d-flex flex-column input-box'>
                        <label htmlFor='username' className='title-light'>Username</label>
                        <input type="text" name="username" className="input" required
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column input-box'>
                        <label htmlFor='password' className='title-light'>Password</label>
                        <div style={{position: "relative"}}>
                            <input type={showPassword ? 'text' : 'password'} style={{paddingRight: '40px'}}
                                   name="password" className="input" required
                                   onChange={e => setPassword(e.target.value)}/>
                            <IconButton style={{position: 'absolute', right: 0, top: 0}}
                                        onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </div>
                    </div>
                    <div className='d-flex flex-column input-box'>
                        <LoadingButton
                            loading={loading}
                            loadingIndicator={
                                <CircularProgress
                                    sx={{
                                        color: "white",
                                    }}
                                    size={24}
                                />
                            }
                            type="submit"
                            className='button'
                        >
                            LOGIN
                        </LoadingButton>
                    </div>
                </form>
            </Paper>
        </div>
    )
}
export default Login
